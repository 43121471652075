export const shufflePermutationsArray = (
    permutationsArr: number[][]
): number[][] => {
    const shuffledPermutationsArr = [...permutationsArr];

    for (let i = shuffledPermutationsArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        [shuffledPermutationsArr[i], shuffledPermutationsArr[j]] = [
            shuffledPermutationsArr[j],
            shuffledPermutationsArr[i],
        ];
    }

    return shuffledPermutationsArr;
};
