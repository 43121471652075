import { cityStatePermutations } from "./constants/cityStatePermutations";

export const getCityName = (permutation: number[]): string => {
    const cityName = Object.keys(cityStatePermutations).find(
        (city) =>
            JSON.stringify(cityStatePermutations[city].permutation) ===
            JSON.stringify(permutation)
    );

    if (cityName) {
        return cityName;
    } else {
        throw new Error("Invalid permutation");
    }
};
