import { CityProps } from "../types";
import { cityPercentOffsets } from "./constants/cityPercentOffsets";
import { cityStatePermutations } from "./constants/cityStatePermutations";

export const getCityProps = (): CityProps[] => {
    const cityProps: CityProps[] = [];
    for (const city in cityPercentOffsets) {
        cityProps.push({
            name: city,
            latPercent: cityPercentOffsets[city].latPercent,
            longPercent: cityPercentOffsets[city].longPercent,
            permutation: cityStatePermutations[city].permutation,
        });
    }

    return cityProps;
};
