export const setCityFlavorText = (cityName: string, points: number): string => {
    if (points > 10) {
        return "I could live here!";
    } else if (points > 6) {
        return "Everyone is so friendly, even with my horrible French!";
    } else if (points > 4) {
        return "The restaurants here are great!";
    } else if (points > 1) {
        return `${cityName} is beautiful, I like it.`;
    } else {
        return "This is okay, but I'm not sure I'd come back.";
    }
};
