export const getScoreResultText = (score: number): string => {
    if (score >= 60) {
        return "This traip was magic. The best time I ever had.";
    } else if (score >= 40) {
        return "That was a great time. It was everything I hoped for.";
    } else if (score >= 25) {
        return "This was a good trip, thanks for taking us.";
    } else {
        return "I feel like I didn't get to see as much as I wanted. Maybe next time.";
    }
};
