import { useContext } from "react";
import { getButtonGroup } from "../../helpers/getButtonGroup";
import { TGControlButtonContainerProps } from "../../types";
import "./TGControlButtonContainer.scss";
import { TGControlButtonGroup } from "./TGControlButtonGroup";
import { GameStateContext } from "../../contexts/GameStateContext";
import { getScoreResultText } from "../../helpers/getScoreResultText";

export const TGControlButtonContainer: React.FC<
    TGControlButtonContainerProps
> = () => {
    const gameState = useContext(GameStateContext);
    const buttonGroup = getButtonGroup(gameState.currentCity);
    const scoreResultText = getScoreResultText(gameState.totalScore);
    return (
        <section className="tg-button-container">
            {gameState.turnNumber <= 21 ? (
                <TGControlButtonGroup buttonGroup={buttonGroup.buttonGroup} />
            ) : (
                <h2 className="status-text">{scoreResultText}</h2>
            )}
        </section>
    );
};
