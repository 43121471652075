import { useContext } from "react";
import "./StatusText.scss";
import { GameStateContext } from "../../contexts/GameStateContext";

export const StatusText = () => {
    const gameState = useContext(GameStateContext);

    return (
        <>
            <p className="status-text">
                Day {gameState.turnNumber}. You have {gameState.totalScore}{" "}
                points. Flavor text: {gameState.cityFlavorText}
            </p>
            <div>
                Goal cities:
                <span className="goal-cities-container">
                    {gameState.goalCities.map((goalCity, index) => (
                        <p
                            key={index}
                            style={{
                                color: goalCity.visited ? "green" : "red",
                            }}
                        >
                            {goalCity.name} &nbsp;
                        </p>
                    ))}
                </span>
            </div>
        </>
    );
};
