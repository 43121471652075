import { shufflePermutationsArray } from "@train-game/processing-module/helpers/shufflePermutations";

interface CityTrainPermutationsTable {
    [city: string]: { permutations: number[][] };
}

const cityTrainPermutations: CityTrainPermutationsTable = {
    Paris: {
        permutations: [
            [1, 2, 3, 4],
            [1, 2, 4, 3],
            [1, 3, 2, 4],
            [1, 4, 3, 2],
            [2, 1, 3, 4],
            [2, 4, 1, 3],
            [2, 4, 3, 1],
            [2, 3, 1, 4],
            [2, 3, 4, 1],
            [4, 3, 1, 2],
        ],
    },
    Marseille: {
        permutations: [
            [1, 2, 3, 4],
            [1, 2, 4, 3],
            [1, 4, 2, 3],
            [1, 3, 4, 2],
            [1, 3, 2, 4],
            [4, 1, 3, 2],
            [3, 1, 4, 2],
            [3, 2, 1, 4],
        ],
    },
    Lyon: {
        permutations: [
            [1, 2, 3, 4],
            [1, 3, 2, 4],
            [1, 3, 4, 2],
            [2, 1, 4, 3],
            [3, 2, 1, 4],
            [2, 1, 4, 3],
            [2, 3, 1, 4],
            [2, 3, 4, 1],
        ],
    },
    Toulouse: {
        permutations: [
            [1, 2, 3, 4],
            [1, 4, 2, 3],
            [1, 2, 4, 3],
            [4, 3, 1, 2],
            [4, 2, 3, 1],
            [3, 2, 4, 1],
        ],
    },
    Nice: {
        permutations: [
            [1, 2, 3, 4],
            [1, 3, 2, 4],
            [1, 4, 3, 2],
            [4, 1, 2, 3],
            [2, 1, 4, 3],
            [2, 3, 1, 4],
        ],
    },
    Nantes: {
        permutations: [
            [1, 2, 3, 4],
            [1, 4, 3, 2],
            [4, 2, 3, 1],
            [3, 2, 1, 4],
            [2, 4, 3, 1],
            [2, 3, 1, 4],
        ],
    },
    Strasbourg: {
        permutations: [
            [1, 2, 3, 4],
            [2, 1, 3, 4],
            [1, 4, 2, 3],
            [1, 3, 2, 4],
            [1, 3, 4, 2],
            [3, 1, 4, 2],
        ],
    },
    Bordeaux: {
        permutations: [
            [1, 2, 3, 4],
            [2, 4, 1, 3],
            [2, 4, 3, 1],
            [4, 2, 1, 3],
            [4, 3, 2, 1],
            [3, 4, 1, 2],
        ],
    },
    Lille: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 4, 2],
            [1, 3, 4, 2],
            [1, 4, 3, 2],
            [1, 4, 2, 3],
            [4, 1, 2, 3],
        ],
    },
    Rennes: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 3, 2],
            [1, 2, 4, 3],
            [1, 3, 2, 4],
            [2, 4, 1, 3],
            [2, 3, 4, 1],
        ],
    },
    Dijon: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 2, 4],
            [3, 2, 1, 4],
            [2, 1, 3, 4],
            [2, 1, 4, 3],
            [4, 2, 3, 1],
        ],
    },
    Rouen: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 2, 3],
            [1, 3, 4, 2],
            [1, 3, 2, 4],
            [3, 4, 1, 2],
            [3, 2, 4, 1],
        ],
    },
    Biarritz: {
        permutations: [
            [1, 2, 3, 4],
            [2, 1, 4, 3],
            [3, 2, 4, 1],
            [1, 2, 4, 3],
            [4, 2, 1, 3],
            [4, 1, 3, 2],
        ],
    },
    Avignon: {
        permutations: [
            [1, 2, 3, 4],
            [2, 1, 4, 3],
            [1, 4, 2, 3],
            [3, 2, 1, 4],
            [3, 1, 4, 2],
        ],
    },
    Annecy: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 2, 4],
            [2, 1, 3, 4],
            [1, 3, 4, 2],
            [1, 2, 4, 3],
        ],
    },
    Colmar: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 2, 3],
            [2, 4, 1, 3],
            [2, 1, 4, 3],
            [1, 2, 4, 3],
        ],
    },
    "La Rochelle": {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 2, 4],
            [3, 2, 1, 4],
            [4, 3, 2, 1],
            [2, 1, 3, 4],
        ],
    },
    Ajaccio: {
        permutations: [
            [1, 2, 3, 4],
            [4, 3, 2, 1],
            [4, 2, 3, 1],
        ],
    },
    Cherbourg: {
        permutations: [
            [1, 2, 3, 4],
            [3, 1, 4, 2],
            [3, 4, 1, 2],
            [1, 4, 2, 3],
        ],
    },
    Montpellier: {
        permutations: [
            [1, 2, 3, 4],
            [2, 4, 3, 1],
            [2, 4, 1, 3],
            [2, 3, 1, 4],
            [3, 2, 1, 4],
            [1, 3, 4, 2],
        ],
    },
    "Aix-en-Provence": {
        permutations: [
            [1, 2, 3, 4],
            [3, 2, 1, 4],
            [3, 4, 2, 1],
            [3, 1, 2, 4],
            [4, 3, 1, 2],
        ],
    },
    Brest: {
        permutations: [
            [1, 2, 3, 4],
            [4, 1, 3, 2],
            [2, 1, 3, 4],
            [3, 1, 4, 2],
            [1, 3, 4, 2],
        ],
    },
    Tours: {
        permutations: [
            [1, 2, 3, 4],
            [3, 4, 2, 1],
            [3, 1, 2, 4],
            [4, 1, 2, 3],
            [2, 4, 1, 3],
            [2, 1, 3, 4],
        ],
    },
    Perpignan: {
        permutations: [
            [1, 2, 3, 4],
            [4, 2, 1, 3],
            [3, 4, 1, 2],
            [2, 4, 3, 1],
            [1, 4, 2, 3],
        ],
    },
};

export const cityTrainShuffledPermutations: CityTrainPermutationsTable =
    Object.fromEntries(
        Object.entries(cityTrainPermutations).map(([city, data]) => [
            city,
            {
                permutations: shufflePermutationsArray(data.permutations),
            },
        ])
    );
