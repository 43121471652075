import { CityPointsTable } from "./constants/initialPointsTable";
import { setCityFlavorText } from "./setCityFlavorText";

export const incrementScore = (
    city: string,
    score: number,
    cityPointsTable: CityPointsTable
): [number, CityPointsTable, string] => {
    const newScore = score + cityPointsTable[city].points;
    const cityFlavorText = setCityFlavorText(
        city,
        cityPointsTable[city].points
    );
    const newCityPointsTable = { ...cityPointsTable };
    newCityPointsTable[city].points = Math.floor(
        newCityPointsTable[city].points / 2
    );

    return [newScore, newCityPointsTable, cityFlavorText];
};
