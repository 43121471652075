interface CityRelativeCoordinatesTable {
    [city: string]: { latPercent: number; longPercent: number };
}

export const cityPercentOffsets: CityRelativeCoordinatesTable = {
    Paris: {
        latPercent: 24.07,
        longPercent: 49.08,
    },
    Marseille: {
        latPercent: 81.49,
        longPercent: 67.52,
    },
    Lyon: {
        latPercent: 56.05,
        longPercent: 64.23,
    },
    Toulouse: {
        latPercent: 78.3,
        longPercent: 43.56,
    },
    Nice: {
        latPercent: 77.57,
        longPercent: 79.2,
    },
    Nantes: {
        latPercent: 41.05,
        longPercent: 25.29,
    },
    Strasbourg: {
        latPercent: 27.01,
        longPercent: 82.01,
    },
    Bordeaux: {
        latPercent: 65.61,
        longPercent: 31.21,
    },
    Lille: {
        latPercent: 5.89,
        longPercent: 53.4,
    },
    Rennes: {
        latPercent: 31.8,
        longPercent: 24.52,
    },
    Dijon: {
        latPercent: 40.03,
        longPercent: 65.35,
    },
    Rouen: {
        latPercent: 18.12,
        longPercent: 41.45,
    },
    Biarritz: {
        latPercent: 79.57,
        longPercent: 25.24,
    },
    Avignon: {
        latPercent: 74.75,
        longPercent: 64.06,
    },
    Annecy: {
        latPercent: 54.47,
        longPercent: 72.15,
    },
    Colmar: {
        latPercent: 32.14,
        longPercent: 79.61,
    },
    "La Rochelle": {
        latPercent: 51.97,
        longPercent: 27.73,
    },
    Ajaccio: {
        latPercent: 95.6,
        longPercent: 88.03,
    },
    Cherbourg: {
        latPercent: 16.09,
        longPercent: 24.91,
    },
    Montpellier: {
        latPercent: 78.23,
        longPercent: 58.4,
    },
    "Aix-en-Provence": {
        latPercent: 79.11,
        longPercent: 67.96,
    },
    Brest: {
        latPercent: 28.97,
        longPercent: 7.38,
    },
    Tours: {
        latPercent: 40.78,
        longPercent: 38.96,
    },
    Perpignan: {
        latPercent: 87.64,
        longPercent: 52.41,
    },
};
