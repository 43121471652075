import "./City.scss";
import { CityProps } from "../../types";
import { useContext } from "react";
import { GameStateContext } from "../../contexts/GameStateContext";

export const City = ({
    name,
    latPercent,
    longPercent,
    permutation,
}: CityProps) => {
    const positionStyle = {
        top: `${latPercent}%`,
        left: `${longPercent}%`,
    };

    const gameState = useContext(GameStateContext);

    const activeStyle =
        JSON.stringify(gameState.currentPermutation) ===
        JSON.stringify(permutation)
            ? {
                  backgroundColor: "black",
              }
            : {
                  backgroundColor: "white",
              };

    return (
        <div className="tg-city" style={positionStyle}>
            <div className="tg-city-dot" style={activeStyle}></div>
            {name}
        </div>
    );
};
