import {
    TGControlButtonProps,
    TGControlButtonGroupProps,
    TGButtonActionType,
} from "../types";
import { cityTrainShuffledPermutations } from "./constants/cityTrainPermutations";

export const getButtonGroup = (
    currentCity: string
): TGControlButtonGroupProps => {
    const permutations =
        cityTrainShuffledPermutations[currentCity].permutations;
    const buttonGroup: TGControlButtonProps[] = permutations.map(
        (permutation, index) => ({
            label: `Permutation ${index + 1}`,
            actionType: TGButtonActionType.PERMUTE,
            payload: permutation,
        })
    );

    return { buttonGroup };
};
