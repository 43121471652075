import { ControlButtonProps } from "@train-game/control-module/types";
import { GameState } from "@train-game/processing-module/types";
import { CityPointsTable } from "./helpers/constants/initialPointsTable";

//Control
export enum TGButtonActionType {
    PERMUTE = "permute",
}

export interface TGControlButtonProps extends ControlButtonProps {
    label: string;
    actionType: TGButtonActionType;
    payload: number[];
}

export interface TGControlButtonGroupProps {
    buttonGroup: TGControlButtonProps[];
}

export interface TGControlButtonContainerProps {}

//Display
export interface MapProps {
    cities: CityProps[];
}

export interface CityProps {
    name: string;
    latPercent: number;
    longPercent: number;
    permutation: number[];
}

//Processing
export interface TGGameState extends GameState {
    currentCity: string;
    currentPermutation: number[];
    turnNumber: number;
    totalScore: number;
    cityPointsTable: CityPointsTable;
    goalCities: GoalCity[];
    cityFlavorText: string;
}

export interface TGGameStateAction {
    type: TGButtonActionType;
    payload: number[];
}

export interface GoalCity {
    name: string;
    visited: boolean;
}
