import { TGGameState } from "../types";
import { baseCityPoints } from "./constants/initialPointsTable";
import { incrementScore } from "./incrementScore";
import { setCityPoints } from "./setCityPoints";

function initializeGameState() {
    const [cityPointsTable, goalCities] = setCityPoints(baseCityPoints);
    const [initialScore, newCityPointsTable, cityFlavorText] = incrementScore(
        "Paris",
        0,
        cityPointsTable
    );
    const initialGameState: TGGameState = {
        currentCity: "Paris",
        currentPermutation: [1, 2, 3, 4],
        turnNumber: 1,
        totalScore: initialScore,
        cityPointsTable: newCityPointsTable,
        goalCities: goalCities,
        cityFlavorText: cityFlavorText,
    };
    return { cityPointsTable, initialGameState };
}

export const { cityPointsTable, initialGameState } = initializeGameState();
