import { StatusText } from "./StatusText";
import "./StatusPanel.scss";

export const StatusPanel = () => {
    return (
        <section className="status-panel">
            <StatusText />
        </section>
    );
};
