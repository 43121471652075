export interface CityPointsTable {
    [city: string]: { points: number };
}

export const baseCityPoints: CityPointsTable = {
    Paris: {
        points: 1,
    },
    Marseille: {
        points: 1,
    },
    Lyon: {
        points: 1,
    },
    Toulouse: {
        points: 1,
    },
    Nice: {
        points: 1,
    },
    Nantes: {
        points: 1,
    },
    Strasbourg: {
        points: 1,
    },
    Bordeaux: {
        points: 1,
    },
    Lille: {
        points: 1,
    },
    Rennes: {
        points: 1,
    },
    Dijon: {
        points: 1,
    },
    Rouen: {
        points: 1,
    },
    Biarritz: {
        points: 1,
    },
    Avignon: {
        points: 1,
    },
    Annecy: {
        points: 1,
    },
    Colmar: {
        points: 1,
    },
    "La Rochelle": {
        points: 1,
    },
    Ajaccio: {
        points: 1,
    },
    Cherbourg: {
        points: 1,
    },
    Montpellier: {
        points: 1,
    },
    "Aix-en-Provence": {
        points: 1,
    },
    Brest: {
        points: 1,
    },
    Tours: {
        points: 1,
    },
    Perpignan: {
        points: 1,
    },
};
