import { CityPointsTable } from "./constants/initialPointsTable";
import { GoalCity } from "../types";

export const setCityPoints = (
    pointsTable: CityPointsTable
): [CityPointsTable, GoalCity[]] => {
    const newPointsTable: CityPointsTable = { ...pointsTable };
    const shuffledCityNames = Object.keys(pointsTable).sort(
        () => Math.random() - 0.5
    );

    const goalCities: GoalCity[] = shuffledCityNames
        .slice(0, 5)
        .map((cityName) => ({
            name: cityName,
            visited: cityName === "Paris" ? true : false,
        }));

    goalCities.forEach((city) => {
        newPointsTable[city.name].points += 3;
    });

    const reshuffledCityNames = shuffledCityNames.sort(
        () => Math.random() - 0.5
    );

    const randomAddedPoints = [4, 6, 10];

    randomAddedPoints.forEach((pointValue, index) => {
        newPointsTable[reshuffledCityNames[index]].points += pointValue;
    });

    return [newPointsTable, goalCities];
};
