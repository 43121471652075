interface CityStatePermutationsTable {
    [city: string]: { permutation: number[] };
}

export const cityStatePermutations: CityStatePermutationsTable = {
    Paris: {
        permutation: [1, 2, 3, 4],
    },
    Marseille: {
        permutation: [1, 2, 4, 3],
    },
    Lyon: {
        permutation: [1, 3, 2, 4],
    },
    Toulouse: {
        permutation: [1, 3, 4, 2],
    },
    Nice: {
        permutation: [1, 4, 2, 3],
    },
    Nantes: {
        permutation: [1, 4, 3, 2],
    },
    Strasbourg: {
        permutation: [2, 1, 3, 4],
    },
    Bordeaux: {
        permutation: [2, 1, 4, 3],
    },
    Lille: {
        permutation: [2, 4, 1, 3],
    },
    Rennes: {
        permutation: [2, 4, 3, 1],
    },
    Dijon: {
        permutation: [2, 3, 1, 4],
    },
    Rouen: {
        permutation: [2, 3, 4, 1],
    },
    Biarritz: {
        permutation: [3, 1, 2, 4],
    },
    Avignon: {
        permutation: [3, 1, 4, 2],
    },
    Annecy: {
        permutation: [3, 2, 1, 4],
    },
    Colmar: {
        permutation: [3, 2, 4, 1],
    },
    "La Rochelle": {
        permutation: [3, 4, 1, 2],
    },
    Ajaccio: {
        permutation: [3, 4, 2, 1],
    },
    Cherbourg: {
        permutation: [4, 1, 2, 3],
    },
    Montpellier: {
        permutation: [4, 1, 3, 2],
    },
    "Aix-en-Provence": {
        permutation: [4, 2, 1, 3],
    },
    Brest: {
        permutation: [4, 2, 3, 1],
    },
    Tours: {
        permutation: [4, 3, 1, 2],
    },
    Perpignan: {
        permutation: [4, 3, 2, 1],
    },
};
