import "./Map.scss";
import { City } from "./City";
import { getCityProps } from "../../helpers/getCityProps";

export const Map = () => {
    const cityProps = getCityProps();
    const cities = cityProps.map((city) => (
        <City
            key={city.name}
            name={city.name}
            latPercent={city.latPercent}
            longPercent={city.longPercent}
            permutation={city.permutation}
        />
    ));

    return (
        <section className="map">
            <img className="france-img" src="/images/france.gif" alt="map" />
            {cities}
        </section>
    );
};
