import multiplyPermutations from "@train-game/processing-module/helpers/multiplyPermutations";
import { TGButtonActionType, TGGameState, TGGameStateAction } from "../types";
import { getCityName } from "../helpers/getCityName";
import { incrementScore } from "../helpers/incrementScore";

function gameStateReducer(state: TGGameState, action: TGGameStateAction) {
    const newTurnNumber = state.turnNumber + 1;
    switch (action.type) {
        case TGButtonActionType.PERMUTE:
            const newPermutation = multiplyPermutations(
                state.currentPermutation,
                action.payload
            );
            const newCity = getCityName(newPermutation);

            const [newTotalScore, newPointsTable, newCityFlavorText] =
                incrementScore(
                    newCity,
                    state.totalScore,
                    state.cityPointsTable
                );

            const newGoalCities = state.goalCities.map((goalCity) => {
                if (goalCity.name === newCity) {
                    goalCity.visited = true;
                }
                return goalCity;
            });

            const newGameState = {
                currentCity: newCity,
                currentPermutation: newPermutation,
                turnNumber: newTurnNumber,
                totalScore: newTotalScore,
                cityPointsTable: newPointsTable,
                goalCities: newGoalCities,
                cityFlavorText: newCityFlavorText,
            };
            return newGameState;
        default:
            return state;
    }
}

export { gameStateReducer };
